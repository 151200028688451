import Vue from 'vue';
import App from './App'
import Cookies from 'js-cookie'
import store from './store'
import router from './router'
import Web3 from 'web3'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import animate from 'animate.css';
Vue.use(animate);
import '@/styles/main.scss' // global css
import '@/styles/index.css' // global css

import animated from 'animate.css' 
Vue.use(animated)


import Element, {
  Message
} from 'element-ui'
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
})

Vue.prototype.Web3 = Web3
// import * as filters from './filters' // global filters
import SocialSharing from 'vue-social-sharing' // global filters
Vue.use(SocialSharing);


const showMessage = Symbol('showMessage')
/** 
 *  重写ElementUI的Message,达到重置的效果
 */
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info(options, single = true) {
    this[showMessage]('info', options, single)
  }
  error(options, single = true) {
    this[showMessage]('error', options, single)
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        Message[type](options)
      }
    } else {
      Message[type](options)
    }
  }
}

Vue.prototype.$message = new DonMessage()


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  initMask()
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
})

function initMask() {
  // console.log('initMask', store)
  let maskState = store.state.mask.sendMask
  const maskStateArr = Object.keys(maskState)
  maskStateArr.map((item) => {
    if (item !== 'mainNetwork') {
      maskState[item] = false;
    }
  })
  // console.log(store.getters.mask, `store状态`)
};
// function lisenScrol() {
//   let w = this
//   window.onkeydown = function (e) {
//     console.log(e)
//     if (e.keyCode === 17) w.ctrlDown = true
//   },
//     window.onkeyup = function (e) {
//       if (e.keyCode === 17) w.ctrlDown = false
//     },
//     window.getElementsByClassName('c-gant')[0].addEventListener('mousewheel', (e) => {
//       e.preventDefault();
//       if (w.ctrlDown) {
//         let _newTimes = []
//         if (e.wheelDeltaY > 0) {  // 放大
//         } else {  // 缩小
//         }
//       }
//     }, false);
// }



// (function (win) {
//   var tid;
//   function refreshRem() {
//     let designSize = 1920; // 设计图尺寸
//     let html = document.documentElement;
//     let wW = html.clientWidth || 1920;// 窗口宽度
//     let rem = wW * 16 / designSize;
//     console.log('wV', wW)

//     if (wW < 1900 && wW > 1400) {
//       console.log('wV', '1920-1600', wW)
//       html.style.fontSize = 12 + 'px';
//     }
//     // else if (wW < 1600 && wW > 750) {
//     //   html.style.fontSize = 14 + 'px';
//     //   console.log('wV', '1600-750', wW)
//     // } 
//     // else {
//     //   console.log('wV', 'other', wW)
//     //   html.style.fontSize = rem + 'px';
//     // }
//   }

//   win.addEventListener('resize', function () {
//     clearTimeout(tid);
//     tid = setTimeout(refreshRem, 10);
//   }, false);
//   win.addEventListener('pageshow', function (e) {
//     if (e.persisted) {
//       clearTimeout(tid);
//       tid = setTimeout(refreshRem, 10);
//     }
//   }, false);
//   setTimeout(refreshRem(), 0)


// })(window);


if (window.ethereum) {
  handleEthereum();
} else {
  window.addEventListener('ethereum#initialized', handleEthereum, {
    once: true,
  });

  // If the event is not dispatched by the end of the timeout,
  // the user probably doesn't have MetaMask installed.
  setTimeout(handleEthereum, 3000); // 3 seconds
}

function handleEthereum() {
  const {
    ethereum
  } = window;
  if (ethereum && ethereum.isMetaMask) {
    // console.log('Ethereum successfully detected!');
    // Access the decentralized web!
  } else {
    // console.log('Please install MetaMask!');
  }
}

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)


import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

new Vue({
  // el: '#app',
  router,
  store,
  render: h => h(App),

  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('custom-render-trigger')) // 预渲染
  },
}).$mount('#app')