let state = {
  tradeInfo: {},
  tradeOrder: {},
  params: {},
  buyType: "change",
  _amount: 0,
  lottery: 0,
  contractInstance: null,
  trade_id: null,
  key: '',
  skuId: 0,
  data: '',
  up_refresh: null, //刷新页面
  // 选中状态
  finished: false,
  nft2lottery: false,
  //中奖记录信息
  tradeInfomation: [],
  isRouterAlive: true,
  cardType: '',
};

const mutations = {
  POST_GAME_INFO: (state, tradeInfo) => {
    state["tradeInfo"] = tradeInfo;
  },
  CHECK_BEFORE_PLACE_ORDER: (state, tradeOrder) => {
    state["tradeOrder"] = tradeOrder;
  },
  // 卡片类型
  TRANSFER_NFT: (state, type) => {
    state["cardType"] = type;
  },
  PLACE_ORDER: (state, params) => {
    state["params"] = params;
  },
  CHANGE_BUYTYPE: (state, params) => {
    state["buyType"] = params;
  },
  QUERY_LOTTERY_TICKET: (state, params) => {
    state["lottery"] = params;
  },
  UP_TICKET: (state, params) => {
    console.log('up_ticket', params);
    state["lottery"] = params;
  },
  UP_REFRESH: (state, params) => {
    console.log('up_refresh', params);
    state["up_refresh"] = params;
  },
  TRADE_ORDER: (state, id) => {
    state["trade_id"] = id;
  },
  SELECT_KEY(state, key) {
    state["key"] = key;
  },
  SEND_ID(state, skuId) {
    state["skuId"] = skuId;
  },
  SAVE_TITLE(state, data) {
    state["data"] = data;
  },
  SEND_ORDER(state, order_info) {
    state["order_info"] = order_info;
    convertNFT2Other(order_info, function (msg, res) {
      console.log(msg, res);
    });
  },
  NFT_NFT(state, order_info) {
    state["order_info"] = order_info;
  },
  TRADE_DONE(state, finished) {
    state["finished"] = finished;
  },
  TRADE_INFO(state, tradeInfomation) {
    state['tradeInfomation'] = tradeInfomation
  },
  SAVE_ROUTER_ALIVE(state, isRouterAlive) {
    state['isRouterAlive'] = isRouterAlive
  },
  NFT2LOTTERY(state, params) {
    state['nft2lottery'] = params
  },
};

const actions = {
  post_goods_info({
    commit
  }, tradeInfo) {
    commit("POST_GAME_INFO", tradeInfo);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};