const Web3 = require("web3");
const mintABI = require("@/abi/mint.json");
const safeABI = require("@/abi/register.json");


import Vue from "vue";
import Vuex from "vuex";
import store from "@/store/index.js";
Vue.use(Vuex);
let {
  ethereum
} = window;
export async function request_network(type) {
  if (ethereum) {
    let network = await ethereum.request({
      method: "net_version"
    });
    // console.log("net work ========== ", network);
    await store
      .dispatch("web3/set_network", network)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (type === "mint") {
          if (network !== process.env.VUE_APP_MAIN_NETWORK2) {
            return wallet_addEthereumChain_Matic(network);
          }
        } else {
          if (network !== process.env.VUE_APP_MAIN_NETWORK) {
            return wallet_addEthereumChain_ETH(network);
          }
        }
      });
  }
}

export function wallet_addEthereumChain_Matic(network) {
  var web3 = new Web3(window.ethereum);
  let net_code, matic;
  net_code = process.env.VUE_APP_MAIN_NETWORK2;
  matic =
    process.env.VUE_APP_MAIN_NETWORK2 == 137 ? {
      method: "wallet_addEthereumChain",
      params: [{
        //   chainId: web3.utils.numberToHex(137),
        chainId: web3.utils.numberToHex(net_code),
        chainName: "MATIC MAIN",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://polygon-rpc.com"],
        blockExplorerUrls: ["https://polygonscan.com"],
      }, ],
    } : {
      method: "wallet_addEthereumChain",
      params: [{
        //   chainId: web3.utils.numberToHex(blind137),
        chainId: web3.utils.numberToHex(net_code),
        chainName: "MATIC test net",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC", // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"],
      }, ],
    };

  return window.ethereum.request(matic);
}
export function wallet_addEthereumChain_ETH(network) {
  var web3 = new Web3(window.ethereum);
  let net_code, matic;

  net_code = process.env.VUE_APP_MAIN_NETWORK;

  matic =
    process.env.VUE_APP_MAIN_NETWORK == 1 ? {
      method: "wallet_switchEthereumChain",
      params: [{
        //   chainId: web3.utils.numberToHex(137),
        chainId: web3.utils.numberToHex(net_code),
        //   chainName: "以太坊 Ethereum 主网络",
        //   nativeCurrency: {
        //     name: "ETH",
        //     symbol: "ETH", // 2-6 characters long
        //     decimals: 18,
        //   },
        //   rpcUrls: [
        //     "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        //   ],
        //   blockExplorerUrls: ["https://etherscan.io"],
      }, ],
    } : {
      method: "wallet_switchEthereumChain",
      params: [{
        //   chainId: web3.utils.numberToHex(blind137),
        chainId: web3.utils.numberToHex(4),
        //   chainName: "Rinkeby 测试网络",
        //   nativeCurrency: {
        //     name: "ETH",
        //     symbol: "ETH", // 2-6 characters long
        //     decimals: 18,
        //   },
        //   rpcUrls: [
        //     "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        //   ],
        //   blockExplorerUrls: ["https://rinkeby.etherscan.io"],
      }, ],
    };

  return window.ethereum.request(matic);
}

/**
 *  const myContract = new web3.eth.Contract(ABI, Address);
 *  Address -合约地址
 */

export function mintNFT(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .mint(quantity, hash, signature,block_height ,nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: web3.utils.toWei(price, "ether"),
      type: "0x2",
    })
}

export async function getAccount() {
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });
  let account = accounts[0];
  store.commit("user/CHANGE_ADDRESS", account)
  store.dispatch('user/login')
  return account;
}

/**
 *  const myContract = new web3.eth.Contract(ABI, Address);
 *  Address -合约地址
 */

 export function whiteListMint(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .whiteListMint(quantity,hash,signature,block_height,nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

export function passMint(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height,
    pass_id
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .passMint(quantity,pass_id,hash,signature,block_height,nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

export function Mint(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .mint(quantity)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

//领取SBTsafeMint( tokenId, quantity, hash, signature,blockHeight,nonce)
export function safeMint(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height,
    token_id
  } = orderInfo;
  var web3 = new Web3(window.ethereum);

  const myContract = new web3.eth.Contract(safeABI, contract_address);
  return myContract.methods
    .safeMint(token_id, quantity, hash, signature,block_height,nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}