import Cookies from 'js-cookie';
import {
  post_account_token,
  is_bind_email,
  check_nickname
} from '@/api/login/index.js'

const {
  ethereum
} = window;
let state = {
  web3: {
    isMetaMask: false,
    isAccredited: false,
    isConnect: false,
    isInjected: false,
    web3Instance: null,
    coinbase: null,
    balance: null,
    error: null,
    account: null,
    payConfirm: false,
    purchase: 0,
    _amount: 0,
    goods_id: null,
    txhash: null,
  },
  networkId: '',
  contractInstance: null
}

function isMetaMask() {
  return Boolean(ethereum && ethereum.isMetaMask);
}


async function getToken(account, purchase, _amount, goods_id) {
  let data = {
    chain_wallet_id: account
  }
  let res = await post_account_token(data)

  console.log(res, `返回请求信息`)
  if (res.code === 200) {

    // Cookies.set('token',res.data.token, { expires: 10 })
    Cookies.set('token', res.data.token, {
      expires: 1
    })
    console.log('已登陆')
    // dispatch("mask/open_mask" , "payConfirm")
    console.log('已登陆2')
    // GetAngleRewards(Address, account,purchase ,_amount, goods_id , function (msg,res) {
    //     console.log(msg,res)
    // })
  } else {
    console.log(`res error`)
  }
}
async function test_network() {
  const net_version = await ethereum.request({
    method: "net_version"
  });
  return net_version

}
async function requestAccounts() {
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });
  return accounts[0]
}

function toDownload() {
  window.open("https://metamask.io/download.html", "_blank");
}
const mutations = {

  isConnected(state, account, isConnected) {
    state.web3.account = account;
    state.web3.isConnected = isConnected;
    const purchase = state.web3.purchase;
    const _amount = state.web3._amount;
    const goods_id = state.web3.goods_id;
    getToken(account, purchase, _amount, goods_id)
  },
  //设置网络
  SET_NETWORK(state, networkId) {
    state.networkId = networkId;
  },
  //SEND_ORDER 
  SEND_ORDER(state, order_info) {
    state.web3.purchase = order_info.purchase;
    state.web3._amount = order_info._amount;
    state.web3.goods_id = order_info.goods_id;
  }
}


const actions = {
  async is_login({
    dispatch,
    commit,
    rootState
  }) {
    try {
      const results = await Promise.all([test_network(), requestAccounts()]);
      console.log(`results`, results)
      const networkId = results[0];
      const account = results[1]
      //test
      const isConnected = ethereum.isConnected();
      if (isConnected) {
        commit('isConnected', account, isConnected)

        dispatch("mask/closeMask", "orderAccredit", {
          root: true
        })
        dispatch("mask/openMask", "payLoading", {
          root: true
        })
        console.log('isConnected', account, isConnected)
      } else {
        let result = isMetaMask()
        if (result) {} else {
          toDownload()
        }
      }

    } catch (err) {
      console.log(err);
      let result = isMetaMask()
      if (result) {
        // let current_account = await ethereum.request({ method: "eth_accounts" });
      } else {
        toDownload()
      }
    }
  },


  toDownload({
    commit
  }) {
    console.log('registerWeb3 Action being executed')
    getWeb3.then(result => {
      console.log('committing result to registerWeb3Instance mutation')
      commit('TO_DOWNLOAD', result)
    }).catch(e => {
      console.log('error in action registerWeb3', e)
    })
  },
  registerWeb3({
    commit
  }) {
    console.log('registerWeb3 Action being executed')
    getWeb3.then(result => {
      console.log('committing result to registerWeb3Instance mutation')
      commit('registerWeb3Instance', result)
    }).catch(e => {
      console.log('error in action registerWeb3', e)
    })
  },

  set_network({
    commit
  }, network) {
    commit('SET_NETWORK', network)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}