import request from '@/utils/request'
import baseURL from '@/utils/baseURL'


export function place_goods_order(data) {
  return request({
    url: baseURL["trade"] + `/v2/order/place_goods_order`,
    method: "post",
    data,
  });
}
export function place_goods_order_submit(data) {
  return request({
    url: baseURL["trade"] + `/v2/order/place_goods_order_submit`,
    method: "post",
    data,
  });
}

//质押北海
export function stake_a(data) {
  return request({
    url: baseURL["trade"] + `/v2/stake/stake_721a`,
    method: "post",
    data,
  });
}

//取回北海
export function redeem_a(data) {
  return request({
    url: baseURL["trade"] + `/v2/stake/redeem_721a`,
    method: "post",
    data,
  });
}
//SBT
export function redeem_activity_nft(data) {
  return request({
    url: baseURL["trade"] + `/v2/peking_monsters/redeem_activity_nft`,
    method: "post",
    data,
  });
}

//瓶盖 转移
export function caps_transfer(data) {
  return request({
    url: baseURL["trade"] + `/v2/account/caps/transfer`,
    method: "post",
    data,
  });
}

//瓶盖兑换抽奖数
export function purchase_history(data) {
  return request({
    url: baseURL["trade"] + `/v2/peking_monsters/raffle/purchase_history`,
    method: "post",
    data,
  });
}