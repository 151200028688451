<template>
  <div id="layout" ref="layout">
    <Popup class="oh_popup" />
    <div class="main">
      <div class="header">
        <Navigation class="navigation" ref="nav" />
      </div>
      <keep-alive :key="new Date() + ''">
        <transition enter-active-class="animate__animated animate__fadeIn">
          <router-view></router-view>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/my-popup/index.vue";
import Navigation from "@/components/navigation/index.vue";

export default {
  name: "Layout",
  data() {
    return {};
  },
  components: { Popup, Navigation },
  watch: {
    "$store.state.sendMask"(newval, olval) {
      console.log(newval, olval);
    },
  },
};
</script>

<style lang="scss" scoped>
#layout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  font-size: 0;
}

.navigation {
  font-size: 0;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.oh_popup {
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
}
.black {
  backdrop-filter: #fff !important;
}
.header {
  z-index: 500;
}

// PC端样式
@media screen and (min-width: 1024px) {
  .header {
    width: 100%;
    position: fixed;
  }
}
// 移动端样式
@media (max-width: 1024px) {
  .navigation {
    position: fixed;
    left: -10px;
    top: 20px;
  }
}
</style>