<template></template>

<script>
import { wallet_addEthereumChain } from "@/utils/web3.js";
let that = this;
const { ethereum } = window;
import { connectWallet, walletSign ,getCurrentAccount} from "@/utils/login.js";
import Cookies from "js-cookie";
import store from "@/store/index.js";
export default {
  name: "Index",
  data() {
    return {
      token: null,
    };
  },

  beforeCreate() {
    // console.log("registerWeb3 Action dispatched from casino-dapp.vue");
    // this.$store.dispatch('registerWeb3')
  },
  watch: {
    "$store.state.web3.networkId": function (oldValue, newValue) {
      // console.log("oldV", oldValue, newValue);
      this.isMainNet();
    },
  },
  create() {},
  mounted() {
    this.request_network();
    // this.init();
    this.token = Cookies.get("token") || void 0;
    if (!this.token) {
      // this.$store.dispatch("mask/openMask", "walletBind");
    }
  },
  methods: {
    init() {
      this.watchWeb3Event();
    },
    async request_network() {
      if (ethereum) {
        let network = await ethereum.request({ method: "net_version" });
        // console.log("net work ========== ", network);
        await this.$store
          .dispatch("web3/set_network", network)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            this.isMainNet();
          });
      }
    },
    isMainNet() {
      // console.log(
      //   "VUE_APP_MAIN_NETWORK ",
      //   this.$store.state.web3.networkId,
      //   process.env.VUE_APP_MAIN_NETWORK,
      //   process.env.VUE_APP_MAIN_NETWORK2
      // );
      if (
        this.$store.state.web3.networkId == process.env.VUE_APP_MAIN_NETWORK ||
        this.$store.state.web3.networkId == process.env.VUE_APP_MAIN_NETWORK2
      ) {
        this.$store.dispatch("mask/closeMask", "mainNetwork");
      } else {
        this.$store.dispatch("mask/openMask", "mainNetwork");
      }
    },
   
    async walletSignt() {
      let currentAccount = await getCurrentAccount()
       if (currentAccount != null) {
            walletSign(currentAccount)
        }
    },
    //监听网络
    watchWeb3Event() {
      that = this;
      ethereum.on("disconnect", (res) => {
        // console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        // console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        // console.log("LoginCookies");
        Cookies.remove("token");
        store.state.user.userInfo.address = "";
        that.walletSignt();
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        // console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
              that.$store.dispatch("mask/closeMask", "mainNetwork");
              //  this.connectMetaMask()
              // console.log("已切换到连接主网络");
              // window.location.reload();
            } else {
              that.$store.dispatch("mask/openMask", "mainNetwork");
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },
  },
};
</script>
