let state = {
    sendMask: {
        'isBindWallet': false,
        'walletBind': false,
        'mainNetwork': false,
        'stepState': false,
        'stepStateTwo': false,
        'complete': false,
    },
    activeIndex: 1,
    selected: 0,
    selectNumber: 0,
    contractInstance: null,
    showFilterList_m: false,
    showGolden: false
}

const mutations = {
    OPEN_MASK: (state, maskType) => {
        // console.log('maskType=>OPEN_MASK', maskType)
        state.sendMask[maskType] = true;
    },
    CLOSE_MASK: (state, maskType) => {
        // console.log('maskType=>CLOSE_MASK', maskType)
        state.sendMask[maskType] = false;
    },
    changeIndex: (state, activeIndex) => {
        state['activeIndex'] = activeIndex;
    },
    All: (state, selected) => {
        state['selected'] = selected;
    },
    CountSelectNumber: (state, selectNumber) => {
        state['selectNumber'] = selectNumber;
    },
    // 显示移动端筛选条件列表
    ShowFilterListM: (state, showFilterList_m) => {
        state['showFilterList_m'] = showFilterList_m;
    },
    IS_GOLDEN: (state, showGolden) => {
        state['showGolden'] = showGolden;
    }
}
const actions = {
    openMask({
        commit
    }, maskType) {
        commit('OPEN_MASK', maskType)
    },
    closeMask({
        commit
    }, maskType) {
        commit('CLOSE_MASK', maskType)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}