let state = {
  token_ids: [],
}
const mutations = {
  TOKEN_IDS: (state, item) => {
    state.token_ids = item
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}