<template>
  <!-- azuki导航 -->
  <div id="navigation">
    <p class="nav"></p>
  </div>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {

    };
  },
  watch: {
    // 监听路由改变颜色模式
    "$route.path"(newval, oldval) {
      
    },
    deep: true,
    immediate: true,
  },
  methods: {

  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>