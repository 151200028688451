// process.env.VUE_APP_BASE_API + 

const baseURL = process.env.NODE_ENV == 'local' ? {
  item: `https://item` + '.' + process.env.VUE_APP_BASE_API,
  goods: `https://goods` + '.' + process.env.VUE_APP_BASE_API,
  login: `https://login` + '.' + process.env.VUE_APP_BASE_API,
  trade: `https://trade` + '.' + process.env.VUE_APP_BASE_API,
  pekingMonsters:`https://peking-monsters-party` + '.' + process.env.VUE_APP_BASE_API,
  collection:`https://collectnft.ohdat.io`,

  // } : process.env.NODE_ENV == 'development' ? {
  //   item: '/proxy_item',
  //   goods: '/proxy_goods',
  //   login: '/proxy_login',
  //   trade: '/proxy_trade'
} : {
  item: `https://item` + '.' + process.env.VUE_APP_BASE_API,
  goods: `https://goods` + '.' + process.env.VUE_APP_BASE_API,
  login: `https://login` + '.' + process.env.VUE_APP_BASE_API,
  trade: `https://trade` + '.' + process.env.VUE_APP_BASE_API,
  pekingMonsters:`https://peking-monsters-party` + '.' + process.env.VUE_APP_BASE_API,
  collection:`https://collectnft.ohdat.io`,
}

export default baseURL