import Vue from 'vue'

let state = {
  socketInfo: {
    isConnected: false,
    message: '',
    reconnectError: false,
  },
  contractInstance: null,
  event_parmas: null,
  msg: '',
}
const mutations = {
  EVENT_PARMAS: (state, params) => {
    state['event_parmas'] = params;
  },
  SOCKET_ONOPEN: (state, event) => {
    //   Vue.prototype.$socket = event.currentTarget
    state.socketInfo.isConnected = true

  },
  SOCKET_ONCLOSE: (state, msg) => {
    state.socketInfo.isConnected = false
    state.msg = msg
  },
  SOCKET_ONERROR: (state, event) => {
    console.error(state, event)
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE: (state, message) => {
    //   state.socket.message = message
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT: (state, count) => {
    console.info(state, count)
  },
  SOCKET_RECONNECT_ERROR: (state) => {
    state.socket.reconnectError = true;
  },
}
const actions = {
  post_goods_info({
    commit
  }, goods_info) {
    commit('POST_GOODS_INFO', goods_info)

  },
  sendMessage({
    commit
  }, message) {
    // commit('POST_GOODS_INFO', goods_info)
    Vue.prototype.$socket.send(message)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}