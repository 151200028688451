<template>
  <div id="app" class="app" ref="app">
    <Layout />
    <Web3Page />
  </div>
</template>

<script>
import Layout from "@/layout";
import Web3Page from "@/views/web3/index.vue";
export default {
  name: "App",
  components: {
    Layout,
    Web3Page,
  },
  watch:{
    "$store.state.mask.showGolden"(val, oldval) {
      if (val == true) {
        this.$refs.app.className = "dark";
      } else {
        this.$refs.app.className = "light";
      }
    },
    deep: true,
    immediate: true,
  }
};
</script>

<style>
/* ::-webkit-scrollbar {
  display: none;
  scrollbar-width: none !important;
} */
::-webkit-scrollbar {
        width: 1.8px;
       
    }
            
    ::-webkit-scrollbar-track {
        background: #D2A152 !important;
    }
            
    ::-webkit-scrollbar-thumb {
    background: rgb(255, 200, 109);
    box-shadow: 0 0 12px rgba(255, 255, 255, 1);
    border-radius: 20px;
    
    }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.dark{
  background: #000;
}
.light{
  background: #fff;
}
</style>
