import request from '@/utils/request'
import baseURL from '@/utils/baseURL'
// token
export function post_account_token(data) {
  return request({
    // url: baseURL['login'] +  '/v2/account/login',
    url: baseURL['login'] + '/v2/account/login_by_wallet',

    method: 'post',
    // params: query,
    data
  })
}


// 获取hash
export function get_login_hash(data) {
  return request({
    url: baseURL['login'] + '/v2/account/wallet_hash',
    method: 'post',
    // params: query
    data
  })
}


// send && sign
export function submit_login(data) {
  return request({
    url: baseURL['login'] + '/v2/account/login',
    method: 'post',
    // params: query
    data
  })
}
// email订阅
export function email_subscribe(data) {
  return request({
    url: baseURL['login'] + '/v2/account/email_subscribe',
    method: 'post',
    data
  })
}

// send && sign
export function login_by_wallet(data) {
  return request({
    url: baseURL['login'] + '/v2/account/login_by_wallet',
    method: 'post',
    // params: query
    data
  })
} 
