<template>
  <div class="page flex_col">
    <div class="group1 flex_col">
      <div class="main1 flex_col">
        <div class="main2-container flex_col">
          <div class="main_box">
            <!-- 标题 -->
            <div class="title1">
              <p>
                {{ `OWN YOUR BZUKI` }}
              </p>
              <div class="flex_col block_close_btn" @click="closeMask()"></div>
            </div>

            <div class="stepMain">
              <!--  步骤条盒子 -->
              <div class="steps-box">
                <!-- 步骤条 -->
                <div
                  class="Article-steps"
                  :class="
                    stepList.length <= $store.state.mask.activeIndex
                      ? 'step-over'
                      : ''
                  "
                >
                  <!-- 步骤条背景进度条 -->
                  <div class="line">
                    <span
                      class="plan"
                      :style="`height:${
                        $store.state.mask.activeIndex *
                        (100 / (stepList.length - 1))
                      }%`"
                    ></span>
                  </div>
                  <!-- 每步部分 -->
                  <span
                    class="step"
                    v-for="(item, key) in stepList"
                    :key="key"
                    :class="
                      $store.state.mask.activeIndex == item.index ||
                      item.index <= $store.state.mask.activeIndex
                        ? 'step-active'
                        : 'step-start'
                    "
                  >
                    <!-- 步骤数字 -->
                    <span
                      class="step-num font2"
                      :class="{
                        active:
                          $store.state.mask.activeIndex + 1 === item.index,
                      }"
                    >
                      <!-- 边框 -->
                      <span
                        class="border"
                        v-if="$store.state.mask.activeIndex + 1 == item.index"
                      ></span>
                      <!-- 数字 -->
                      <span
                        class="num"
                        :class="{
                          active:
                            $store.state.mask.activeIndex + 1 === item.index,
                        }"
                        >{{ item.index }}</span
                      >
                    </span>
                    <!-- 步骤描述 -->
                    <p
                      class="title font1"
                      :class="{
                        active:
                          $store.state.mask.activeIndex + 1 === item.index,
                      }"
                    >
                      {{ item.title }}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 掉落香蕉容器 -->
          <div class="right">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/bananaTree.png" alt class="tree" />
            <div class="bananaContent" ref="bananaContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "step",
  data() {
    return {
      activeIndex: 0,
      stepList: [
        {
          index: 1,
          title: `Initialize your wallet`,
        },
        {
          index: 2,
          title: `Confirm minting`,
        },
        // {
        //   index: 3,
        //   title: "Confirm staking"
        // },
      ],
      timer: null,

      bananaContent: null,
      flag: true,
      num: 0,
    };
  },
  mounted() {
    this.isPC();
  },
  watch: {
  },
  methods: {
    /*
      判断是否是pc设备
    */
    isPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          this.flag = false;
          break;
        }
      }
      if (window.screen.width >= 1024) {
        // PC端
        this.flag = true;
      } else if (window.screen.width < 1024) {
        // 移动端
        this.flag = false;
      }
      return this.flag;
    },

    randomNumber() {
      this.timer = setInterval(() => {
        this.drop();
      }, 500);
    },

    // 掉落香蕉
    drop() {
      console.log("this.timer");
      // let a= Math.random()*3+1

      // for (var i=0;i<a;i++){
      //   this.createBanana();
      // }
       this.createBanana();
      // setTimeout(() => {
      //   this.createBanana();
      // }, 200);
      // setTimeout(() => {
      //   this.createBanana();
      // }, 700);
      // setTimeout(() => {
      //   this.createBanana();
      // }, 1300);
    },
    // 创建香蕉
    createBanana() {
      // 创建元素
      var banana = document.createElement("img");
      banana.setAttribute("class", "banana");
      banana.setAttribute("src", "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/banana.png");
      banana.style["position"] = "absolute";
      banana.style["transform"] = `rotate(${Math.floor(
        Math.random() * 360
      )}deg)`;

      // PC端
      if (this.flag == true) {
        banana.style["width"] = "50PX";
        banana.style["top"] = "150PX";
        // 随机位置和旋转角度
        banana.style["left"] = `${Math.floor(Math.random() * 50) + 200}PX`;
        // 掉落动画
        setTimeout(() => {
          banana.style["transition-property"] = "transform";
          banana.style["transition-timing-function"] = "ease-out";
          banana.style["transition-duration"] = "1s";
          banana.style["transform"] = `rotate(${Math.floor(
            Math.random() * 360
          )}deg)`;
          banana.style["transform"] = "translateY(220PX)";
        }, 100);
      }
      // 移动端
      else if (this.flag == false) {
        banana.style["width"] = "30PX";
        banana.style["top"] = "120PX";
        // 随机位置和旋转角度
        banana.style["left"] = `${Math.floor(Math.random() * 20) + 50}PX`;
        // 掉落动画
        setTimeout(() => {
          banana.style["transition-property"] = "transform";
          banana.style["transition-timing-function"] = "ease-out";
          banana.style["transition-duration"] = ".7s";
          banana.style["transform"] = `rotate(${Math.floor(
            Math.random() * 360
          )}deg)`;
          banana.style["transform"] = "translateY(150PX)";
        }, 100);
      }
      // 添加元素
      this.$refs.bananaContent.appendChild(banana);

      // 删除元素
      setTimeout(() => {
        this.$refs.bananaContent.removeChild(banana);
      }, 1000);
    },
    closeMask() {
      this.$store.dispatch("mask/closeMask", "stepState");
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>