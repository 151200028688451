var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page flex_col"},[_c('div',{staticClass:"group1 flex_col"},[_c('div',{staticClass:"main1 flex_col"},[_c('div',{staticClass:"main2-container flex_col"},[_c('div',{staticClass:"main_box"},[_c('div',{staticClass:"title1"},[_c('p',[_vm._v(" "+_vm._s("OWN YOUR BZUKI")+" ")]),_c('div',{staticClass:"flex_col block_close_btn",on:{"click":function($event){return _vm.closeMask()}}})]),_c('div',{staticClass:"stepMain"},[_c('div',{staticClass:"steps-box"},[_c('div',{staticClass:"Article-steps",class:_vm.stepList.length <= _vm.$store.state.mask.activeIndex
                    ? 'step-over'
                    : ''},[_c('div',{staticClass:"line"},[_c('span',{staticClass:"plan",style:(("height:" + (_vm.$store.state.mask.activeIndex *
                      (100 / (_vm.stepList.length - 1))) + "%"))})]),_vm._l((_vm.stepList),function(item,key){return _c('span',{key:key,staticClass:"step",class:_vm.$store.state.mask.activeIndex == item.index ||
                    item.index <= _vm.$store.state.mask.activeIndex
                      ? 'step-active'
                      : 'step-start'},[_c('span',{staticClass:"step-num font2",class:{
                      active:
                        _vm.$store.state.mask.activeIndex + 1 === item.index,
                    }},[(_vm.$store.state.mask.activeIndex + 1 == item.index)?_c('span',{staticClass:"border"}):_vm._e(),_c('span',{staticClass:"num",class:{
                        active:
                          _vm.$store.state.mask.activeIndex + 1 === item.index,
                      }},[_vm._v(_vm._s(item.index))])]),_c('p',{staticClass:"title font1",class:{
                      active:
                        _vm.$store.state.mask.activeIndex + 1 === item.index,
                    }},[_vm._v(" "+_vm._s(item.title)+" ")])])})],2)])])]),_c('div',{staticClass:"right"},[_c('img',{staticClass:"tree",attrs:{"src":"https://img.ohdat.io/peking_monsters/website/assets/icon/mint/bananaTree.png","alt":""}}),_c('div',{ref:"bananaContent",staticClass:"bananaContent"})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }