let state = {
  orderInfo: {
    white_list_status: 0,
    goods_name: '',
    goods_price: '',
    goods_type: '',
    project_name: '',
    stock: '',
    max: '',
  },
  contractInstance: null,
  openInfo: [],
  countList: []
}

const mutations = {
  POST_GOODS_INFO: (state, goods_info) => {
    state.orderInfo.stock = goods_info.stock;
  },
  GET_OPEN_RECORD: (state, openInfo) => {
    state.openInfo = openInfo;
  },
  GET_COUNTLIST: (state, countList) => {
    state.countList = countList;
  },
}

const actions = {
  post_goods_info({
    commit
  }, goods_info) {
    commit('OPEN_MASK', goods_info)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}