import Cookies from "js-cookie";
import store from "@/store/index.js";
let state = {
  userInfo: {
    isInjected: false,
    web3Instance: null,
    networkId: null,
    coinbase: null,
    balance: null,
    error: null,
    showMenu: false,
    address: "",
    token: '',
  },
  contractInstance: null,
}

const mutations = {
  CLEAR_STATE(state) {
    Cookies.remove('token')
    state.userInfo.address = '';
  },
  SHOW_STATUS: (state, showM) => {
    state.userInfo.showMenu = showM;
  },
  LOGIN_INIT(state) {
    state.userInfo.logined = true;
    console.log("state.userInfo.logined", 'true');
  },
  // START_DROP(state, start) {
  //   state.start = start;
  // },
  CHANGE_ADDRESS: (state, address) => {
    console.log(state)
    console.log(address)
  }
}

const actions = {
  login({
    commit
  }) {
    console.log("LOGIN_INIT actions");
    commit("LOGIN_INIT");
  },
  logout({
    commit
  }) {
    commit('CLEAR_STATE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}