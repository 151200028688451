let state = {
    totalNumber: '0000',
};

const mutations = {
    SAVE_TOTAL_NUMBER: (state, totalNumber) => {
        state['totalNumber'] = totalNumber
    },
};

export default {
    state,
    mutations,
};