<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <template>
        <!-- 步骤条 -->
        <Step v-if="$store.getters.mask['stepState']" />
      </template>
    </transition>
  </div>
</template>

<script>
import Step from "@/components/my-popup/step/index.vue";
export default {
  name: "Popup",
  components: {
    Step,
  },
};
</script>

<style>
div {
  --animate-duration: 0.5s;
}
</style>